import React, { useState, useCallback } from "react";
import "./App.css";
import { Card, Tabs, Page, Button } from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import Fields from "./Fields";
import AddNewGroup from "./AddNewGroup";

function Test() {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "Step-1",
      content: "Step 1 (Products)",
      accessibilityLabel: "Step 1",
    },
    {
      id: "Step-2",
      content: "Step 2 (Fields)",
      accessibilityLabel: "Step 2",
    },
    {
      id: "Step-3",
      content: "Step 3 (Save)",
      accessibilityLabel: "Step 3",
    },
  ];

  return (
    <Page fullWidth breadcrumbs={[{ content: "Home", url: "/groups" }]}>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <Card.Section>
            <div>
              {(() => {
                if (selected === 0) {
                  return <AddNewGroup />;
                } else if (selected === 1) {
                  return <Fields />;
                } else if (selected === 2) {
                  return (
                    <Button primary url={process.env.PUBLIC_URL +"/groups"}>
                      Save
                    </Button>
                  );
                }
              })()}
            </div>
          </Card.Section>
        </Tabs>
        
      </Card>
    </Page>
  );
}
export default Test;
