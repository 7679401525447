import React, { useState, useCallback } from "react";
import "./App.css";
import { Redirect } from "react-router";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  AppProvider,
  Page,
  Card,
  TextField,
  FormLayout,
  Checkbox,
  Layout,
  TextContainer,
  Toast,
  Frame,
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";

function ManageGroups() {
  const { id, name } = useParams();
  const productRedirectUrl = "/products/" + id + "/" + 0;
  const collectionRedirectUrl = "/collections/" + id + "/" + name;

  const [isProduct, setisProduct] = useState();
  const [isCollection, setisCollection] = useState();
  const [isTag, setisTag] = useState();

  const [groupName, setGroupName] = useState(name);
  const handlegroupName = useCallback((newValue) => setGroupName(newValue), []);

  const onChangeProduct = useCallback(
    (newChecked) => setisProduct(newChecked),
    []
  );
  const onChangeCollection = useCallback(
    (newChecked) => setisCollection(newChecked),
    []
  );

  const onChangeTag = useCallback((newChecked) => setisTag(newChecked), []);

  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content="Group updated" onDismiss={toggleActive} />
  ) : null;

  const handleSubmit = () => {
    // const url= window.location.hostname;
    // const data1=url.split(".");
    // console.log(data1[0]);
    const group = {
      shop: "visual-app-final",
      group_name: groupName,
      product: isProduct,
      collection: isCollection,
      tag: isTag,
      id: id,
    };
    axios
      .post("https://apptest.webgarh.net/visualapp/api/group_update.php", {
        group,
      })
      .then((res) => {
        console.log(res.data);
        toggleActive();
      });
  };

  return (
    <AppProvider>
      <Page
        fullWidth
        title="Manage group"
        breadcrumbs={[{ content: "Home", url: "/groups" }]}
        primaryAction={{ content: "save", onAction: () => handleSubmit() }}
      >
        <Card sectioned>
          <FormLayout>
            <Layout>
              <Layout.Section oneThird>
                <TextContainer>
                  <p>Group name</p>
                </TextContainer>
              </Layout.Section>
              <Layout.Section oneHalf>
                <TextField value={groupName} onChange={handlegroupName} />
                <span style={{ marginRight: 10 }}></span>
              </Layout.Section>
            </Layout>
            <Layout>
              <Layout.Section oneThird>
                <TextContainer>
                  <p>Product Selection By</p>
                </TextContainer>
              </Layout.Section>
              <Layout.Section oneHalf>
                <Checkbox
                  label="Products "
                  checked={isProduct}
                  onChange={onChangeProduct}
                />
                <span style={{ marginRight: 20 }}></span>
                <Checkbox
                  label="Collections"
                  checked={isCollection}
                  onChange={onChangeCollection}
                />
                <span style={{ marginRight: 20 }}></span>
                <Checkbox label="Tags" checked={isTag} onChange={onChangeTag} />
              </Layout.Section>
            </Layout>
          </FormLayout>
        </Card>

        {(() => {
          if (isProduct === true || isTag === true) {
            return <Redirect to={productRedirectUrl} />;
          }
          if (isCollection === true) {
            return <Redirect to={collectionRedirectUrl} />;
          }
        })()}
        {/* <PageActions
            primaryAction={{
              content: "Save",
            }}
          /> */}
      </Page>
      <div style={{ height: "250px" }}>
        <Frame>{toastMarkup}</Frame>
      </div>
    </AppProvider>
  );
}

export default ManageGroups;
