import React, { Component } from "react";
import "./App.css";
import { Redirect } from "react-router";
import axios from "axios";
import {
  AppProvider,
  Page,
  Card,
  TextField,
  FormLayout,
  Checkbox,
  Layout,
  TextContainer,
  PageActions,
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";

class AddNewGroup extends Component {
  //   constructor() {
  //     super();
  //   }
  state = { valueOne: "", isProduct: false, isCollection: false, 
  isTag: false,redirect: false,redirectUrl:"" };
  onChangeProduct = () => {
    this.setState((initialState) => ({
      isProduct: !initialState.isProduct,
    }));
    // this.handleSubmit();
  };
  onChangeCollection = () => {
    this.setState((initialState) => ({
      isCollection: !initialState.isCollection,
    }));
    // this.handleSubmit();
  };
  onChangeTag = () => {
    this.setState((initialState) => ({
      isTag: !initialState.isTag,
    }));
    // this.handleSubmit();
  };
  handleSubmit = () => {
    const url= window.location.hostname;
    const data1=url.split(".");
    // console.log(data1[0]);
    const group = {
      shop: data1[0],
      group_name: this.state.valueOne,
      product:this.state.isProduct,
      collection:this.state.isCollection,
      tag:this.state.isTag,
    };
    axios
      .post("https://apptest.webgarh.net/visualapp/api/group_insert.php", { group })
      .then((res) => {
        console.log(res.data);
        this.setState({redirectUrl:"/products/"+res.data.id+"/"+res.data.name});
        this.setState({ redirect: true });
      });
  };
  render() {
    return (
      <AppProvider>
        <Page
          title="Add new group"
          // breadcrumbs={[{ content: "Home", url: "/groups" }]}
          // primaryAction={{ content: "Save" }}
        >
          <Card sectioned>
              <FormLayout>
                <Layout>
                  <Layout.Section oneThird>
                    <TextContainer>
                      <p>Group name</p>
                    </TextContainer>
                  </Layout.Section>
                  <Layout.Section oneHalf>
                    <TextField
                      // label=""
                      type="text"
                      value={this.state.valueOne}
                      onChange={(newValue) =>
                        this.setState({ valueOne: newValue })
                      }
                    />
                  </Layout.Section>
                  <Layout.Section oneThird>
                    <TextContainer>
                      <p>Product Selection By</p>
                    </TextContainer>
                  </Layout.Section>
                  <Layout.Section oneHalf>
                    <Checkbox
                      label="Products "
                      checked={this.state.isProduct}
                      onChange={this.onChangeProduct}
                    />
                    <span style={{ marginRight: 20 }}></span>
                    <Checkbox
                      label="Collections"
                      checked={this.state.isCollection}
                      onChange={this.onChangeCollection}
                    />
                    <span style={{ marginRight: 20 }}></span>
                    <Checkbox
                      label="Tags"
                      checked={this.state.isTag}
                      onChange={this.onChangeTag}
                    />
                  </Layout.Section>
                </Layout>
              </FormLayout>
          </Card>
          { this.state.redirect ? (<Redirect push to={this.state.redirectUrl}/>) : null }

          {/* {(() => {
            if (
              this.state.isProduct === true ||
              this.state.isCollection === true ||
              this.state.isTag === true
            ) {return <Redirect to="/products" />;}
          })()} */}
          <PageActions
            primaryAction={{
              content: "Next",
              onAction:() => this.handleSubmit(),
            }}
          />
        </Page>
      </AppProvider>
    );
  }
}

export default AddNewGroup;
