import React, { useIndexResourceState } from "react";
import "./App.css";
import {
  Card,
  IndexTable,
  TextStyle} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";

function ProductTable() {
    const customers = [
      {
        id: '3412',
        url: 'customers/341',
        name: 'Mae Jemison',
        location: 'Decatur, USA',
        orders: 20,
        amountSpent: '$2,400',
      },
      {
        id: '2562',
        url: 'customers/256',
        name: 'Ellen Ochoa',
        location: 'Los Angeles, USA',
        orders: 30,
        amountSpent: '$140',
      },
    ];
    const resourceName = {
      singular: 'customer',
      plural: 'customers',
    };
  
    const resourceIDResolver = (customers) => {
        return customers.node.id;
      };

      const {
        selectedResources,
        allResourcesSelected,
        handleSelectionChange,
      } = useIndexResourceState(customers, {
        resourceIDResolver,
      });
    const rowMarkup = customers.map(
      ({id, name, location, orders, amountSpent}, index) => (
        <IndexTable.Row
          id={id}
          key={id}
          selected={selectedResources.includes(id)}
          position={index}
        >
          <div style={{padding: '1.2rem 1.6rem'}}>
            <p>
              <TextStyle variation="strong">{name}</TextStyle>
            </p>
            <p>{location}</p>
            <p>{orders}</p>
            <p>{amountSpent}</p>
          </div>
        </IndexTable.Row>
      ),
    );
  
    return (
      <div style={{width: '430px'}}>
        <Card>
          <IndexTable
            resourceName={resourceName}
            itemCount={customers.length}
            selectedItemsCount={
              allResourcesSelected ? 'All' : selectedResources.length
            }
            onSelectionChange={handleSelectionChange}
            condensed
            headings={[
              {title: 'Name'},
              {title: 'Location'},
              {title: 'Order count'},
              {title: 'Amount spent'},
            ]}
          >
            {rowMarkup}
          </IndexTable>
        </Card>
      </div>
    );
  }
export default ProductTable;