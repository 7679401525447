import React, { Component } from "react";
import "./App.css";
import axios from "axios";
import {
  AppProvider,
  Page,
  Button,
  Layout,
  Card,
  TextContainer,
  Heading,
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";

class Home extends Component {
  state = {
    persons: [],
  };

  componentDidMount() {
    axios
      .get(`https://apptest.webgarh.net/visualapp/api/products_api.php`)
      .then((res) => {
        const persons = res.data;
        // console.log(res.data);
        this.setState({ persons });
        console.log(persons);
      });
  }

  render() {
    return (
      <AppProvider>
        <Page className="App" title="Welcome to Vishal Product Fields App">
          {/* {this.state.data.map((person) => <TableRow  data = {person.title} />)} */}
                {/* {this.state.persons.map(persons => <h2>{persons.product.id}</h2>)} */}
          <Layout>
            <Layout.Section oneThird>
              <Card
                primaryFooterAction={{ content: "Add Plan" }}
                secondaryFooterActions={[
                  { content: "Learn more.", url: "/textonly" },
                ]}
              >
                <img
                  alt=""
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                />
                <div style={{ padding: 15 }}>
                  <TextContainer>
                    <Heading>Text Only Plan</Heading>
                    <p>
                      Shopify POS is the easiest way to sell your products in
                      person. Available for iPad, iPhone, and Android.
                    </p>
                  </TextContainer>
                </div>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card
                primaryFooterAction={{ content: "Add Plan" }}
                secondaryFooterActions={[
                  { content: "Learn more.", url: "/Photoonly" },
                ]}
              >
                <img
                  alt=""
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                />
                <div style={{ padding: 15 }}>
                  <TextContainer>
                    <Heading>Photo Only Plan</Heading>
                    <p>
                      Shopify POS is the easiest way to sell your products in
                      person. Available for iPad, iPhone, and Android.
                    </p>
                  </TextContainer>
                </div>
              </Card>
            </Layout.Section>

            <Layout.Section oneThird>
              <Card
                primaryFooterAction={{ content: "Add Plan" }}
                secondaryFooterActions={[
                  { content: "Learn more.", url: "/textandphoto" },
                ]}
              >
                <img
                  alt=""
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
                />
                <div style={{ padding: 15 }}>
                  <TextContainer>
                    <Heading>Text and Photo Plan</Heading>
                    <p>
                      Shopify POS is the easiest way to sell your products in
                      person. Available for iPad, iPhone, and Android.
                    </p>
                  </TextContainer>
                </div>
              </Card>
            </Layout.Section>
          </Layout>
          <div className="App" style={{ marginTop: 50 }}>
            <Button primary url="/groups">
              Let's Get Started
            </Button>
          </div>
        </Page>
      </AppProvider>
    );
  }
}

export default Home;
