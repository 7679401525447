import React, { Component } from "react";
import "./App.css";
import { AppProvider, Page, CalloutCard, Layout, List, Card } from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";

class PhotoOnly extends Component {
  render() {
    return (
      <AppProvider>
        <Page
          title="Photo Only Plan"
          breadcrumbs={[{ content: "Home", url: "/"  }]}
        >
          <CalloutCard
            title="Customize the style of your Product"
            illustration="https://cdn.shopify.com/s/assets/admin/checkout/settings-customizecart-705f57c725ac05be5a34ec20c05b94298cb8afd10aac7bd9c7ad02030f48cfa0.svg"
            primaryAction={{
              content: "Use Plan",
              url: "/photoonlyplan",
            }}
          >
            <p style={{display:"block"}}>
              By using this <strong>Paid</strong> Plan Yoc enable image Customization over the product image.
            </p>
          </CalloutCard>
          <div style={{margin:10, display:"none"}}>
          <Layout>
          <Layout.Section oneThird></Layout.Section>
            <Layout.Section oneThird>
              <Card
                title="Paid"
                // secondaryFooterActions={[{ content: "Edit shipment" }]}
                // primaryFooterAction={{ content: "Add tracking number" }}
              >
                <Card.Section >
                  <List>
                    <List.Item>Price: $4.99</List.Item>
                    <List.Item>Use product image as background</List.Item>
                    <List.Item>Rotate uploaded image</List.Item>
                    <List.Item>Uploaded image Size control</List.Item>
                    <List.Item>Move over product image</List.Item>
                    <List.Item>Set Image dimentions</List.Item>
                  </List>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird></Layout.Section>
          </Layout>
          </div>
        </Page>
      </AppProvider>
    );
  }
}


export default PhotoOnly;
