import React, { useState, useCallback, useEffect } from "react";
import "./App.css";
import axios from "axios";
import {
  AppProvider,
  Page,
  Button,
  Layout,
  List,
  Card,
  ChoiceList,
  Form,
  FormLayout,
  TextField,
  Select,
  Toast,
  Frame
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import { Redirect } from "react-router";


function EditField2() {
  const [selected, setSelected] = useState(["1"]);

  const [redirect, setredirect]=useState(false);

  const [redirectURl, setredirectURl]=useState(false);
  const handleChange2 = useCallback((value) => setSelected(value), []);

  const [selected3, setSelected3] = useState(["1"]);

  const handleChange3 = useCallback((value) => setSelected3(value), []);

  const [selected4, setSelected4] = useState(["1"]);

  const handleChange4 = useCallback((value) => setSelected4(value), []);

  const [maxTextWords, setMaxTextWords] = useState("1");

  const handleMaxTextWords = useCallback(
    (newValue) => setMaxTextWords(newValue),
    []
  );

  const [minDimentionHeight, setminDimentionHeight] = useState("400");

  const handleMinDimentionHeight = useCallback(
    (newValue) => setminDimentionHeight(newValue),
    []
  );

  const [minDimentionWidth, setminDimentionWidth] = useState("300");

  const handleMinDimentionWidth = useCallback(
    (newValue) => setminDimentionWidth(newValue),
    []
  );

  const [maxDimentionHeight, setmaxDimentionHeight] = useState("500");

  const handlemaxDimentionHeight = useCallback(
    (newValue) => setmaxDimentionHeight(newValue),
    []
  );

  const [maxDimentionWidth, setmaxDimentionWidth] = useState("350");

  const handlemaxDimentionWidth = useCallback(
    (newValue) => setmaxDimentionWidth(newValue),
    []
  );

  const [charallowed, setCharallowed] = useState("jpeg");

  const handleCharallowed = useCallback((value) => setCharallowed(value), []);

  const options = [
    { label: "jpg", value: "jpg" },
    { label: "jpeg", value: "jpeg" },
    { label: "png", value: "png" },
    { label: "gif", value: "gif" },
  ];

  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content="Field Updated" onDismiss={toggleActive} />
  ) : null;


  useEffect(() => {
    // const url= window.location.hostname;
    // const data1=url.split(".");
    // // console.log(data1[0]);
    // const shop = {
    //   // shop: data1[0],

    // };
    axios
      .post("https://apptest.webgarh.net/visualapp/photo_setting_update.php", 
      {
        shop: "visual-app-final",
      })
      .then((res) => {
        console.log(res.data);
        setSelected3(res.data.original_image);
        setSelected(res.data.overlap);
        
        // if (res.data.fonts === "0") {
        //   setSelected4("No3");
        // } else {
        //   setSelected4("Yes3");
        // }
        if (res.data.photo_frame_size !== "") {
          const minDimensions = res.data.photo_frame_size.split("/");
          setminDimentionHeight(minDimensions[0]);
          setminDimentionWidth(minDimensions[1]);
        }
        if (res.data.photo_frame_size !== "") {
          const minDimensions = res.data.photo_frame_size.split("/");
          setminDimentionHeight(minDimensions[0]);
          setminDimentionWidth(minDimensions[1]);
        }
        if (res.data.dimensions !== "") {
          const maxDimensions = res.data.dimensions.split("/");
          setmaxDimentionHeight(maxDimensions[0]);
          setmaxDimentionWidth(maxDimensions[1]);
        }
        if (res.data.photo_size !== "") {
          setMaxTextWords(res.data.photo_size);
        }
        if (res.data.image_format !== "") {
          setCharallowed(res.data.image_format);
        }
      });
  }, []);

   const handleSave = () => {
     const minDimen=minDimentionHeight+"/"+minDimentionWidth;
     const maxDimen=maxDimentionHeight+"/"+maxDimentionWidth;
    axios
      .post("https://apptest.webgarh.net/visualapp/photo_setting_update.php",
       {
        shop: "visual-app-final",
             overlapValue:selected[0],
             pencilValue:"0",
             b_wValue:"0",
             nostalgiaValue:"0",
             photoSize:maxTextWords,
             photoDimention:maxDimen,
             frameDimention:minDimen,
             originalImgValue:selected3[0],
             image_format:charallowed
      })
      .then((res) => {
        console.log(res.data);
        setSelected3(res.data.original_image);
        setSelected(res.data.overlap);
        
        // if (res.data.fonts === "0") {
        //   setSelected4("No3");
        // } else {
        //   setSelected4("Yes3");
        // }
        if (res.data.photo_frame_size !== "") {
          const minDimensions = res.data.photo_frame_size.split("/");
          setminDimentionHeight(minDimensions[0]);
          setminDimentionWidth(minDimensions[1]);
        }
        if (res.data.photo_frame_size !== "") {
          const minDimensions = res.data.photo_frame_size.split("/");
          setminDimentionHeight(minDimensions[0]);
          setminDimentionWidth(minDimensions[1]);
        }
        if (res.data.dimensions !== "") {
          const maxDimensions = res.data.dimensions.split("/");
          setmaxDimentionHeight(maxDimensions[0]);
          setmaxDimentionWidth(maxDimensions[1]);
        }
        if (res.data.photo_size !== "") {
          setMaxTextWords(res.data.photo_size);
        }
        if (res.data.image_format !== "") {
          setCharallowed(res.data.image_format);
        }
        toggleActive();

      });
    };


  return (
    <AppProvider>
      <Page
      fullWidth
        title="Edit Field"
        breadcrumbs={[{ content: "Home", url: "/process" }]}
        primaryAction={{ content: "save" , onAction:() => handleSave(), }}
      >
        <Layout>
          <Layout.Section oneHalf>
            <Card title="Edit Field (002)" sectioned>
              {/* <List>
                <List.Item>Price: Paid ($5.00)</List.Item>
                <List.Item>Customizable image over the product photo</List.Item>
              </List> */}
              <Form>
                <FormLayout>
                  <ChoiceList
                    title="Allow visual customization ?"
                    choices={[
                      { label: "Yes", value: "1" },
                      { label: "No", value: "0" },
                    ]}
                    selected={selected3}
                    onChange={handleChange3}
                  />
                  <div style={{ marginTop: 20 }}></div>
                  <ChoiceList
                    title="Use product image as background?"
                    choices={[
                      { label: "Yes", value: "1" },
                      { label: "No", value: "0" },
                    ]}
                    selected={selected}
                    onChange={handleChange2}
                  />
                  <div style={{ marginTop: 20, maxWidth: 300 }}>
                    <TextField
                      label="Max image size allowed (in mb):"
                      type="number"
                      value={maxTextWords}
                      onChange={handleMaxTextWords}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}></div>
                  <FormLayout.Group title="Max image dimensions allowed">
                    <TextField
                      type="number"
                      label="Height(px)"
                      value={maxDimentionHeight}
                      onChange={handlemaxDimentionHeight}
                    />
                    <TextField
                      type="number"
                      label="Width (px)"
                      value={maxDimentionWidth}
                      onChange={handlemaxDimentionWidth}
                    />
                  </FormLayout.Group>
                  <div style={{ marginTop: 20 }}></div>
                  <FormLayout.Group title="Min image dimensions allowed">
                    <TextField
                      type="number"
                      label="Height(px)"
                      value={minDimentionHeight}
                      onChange={handleMinDimentionHeight}
                    />
                    <TextField
                      type="number"
                      label="Width (px)"
                      value={minDimentionWidth}
                      onChange={handleMinDimentionWidth}
                    />
                  </FormLayout.Group>
                  <div style={{ marginTop: 20, maxWidth: 300 }}>
                    <Select
                      label="Allowed image formats"
                      options={options}
                      onChange={handleCharallowed}
                      value={charallowed}
                    />
                  </div>

                  <div style={{ marginTop: 20, display: "none" }}>
                    <ChoiceList
                      title="Allow spaces ?"
                      choices={[
                        { label: "Yes", value: "1" },
                        { label: "No", value: "0" },
                      ]}
                      selected={selected4}
                      onChange={handleChange4}
                    />
                  </div>
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
          <div style={{marginBottom:20}} onClick={()=>{setredirectURl('/editfield');setredirect(true);}}>
            <Card title="Field 001" sectioned>
              <List>
                <List.Item>Price type: free</List.Item>
                <List.Item>Ordinary text entry</List.Item>
              </List>
              <p> </p>
            </Card>
            </div>
            <div style={{marginBottom:20}} onClick={()=>{setredirectURl('/editfield3');setredirect(true);}}>
            <Card title="Field 003" sectioned>
              <List>
                <List.Item>Price: Paid ($6.99)</List.Item>
                <List.Item>Customizable image over the product photo</List.Item>
              </List>
              <p> </p>
            </Card>
            </div>
            <div className="App" style={{ marginTop: 50 }}>
              <Button primary url="/">
                Add New
              </Button>
            </div>
          </Layout.Section>
        </Layout>
      </Page>
      <div style={{ height: "250px" }}>
        <Frame>{toastMarkup}</Frame>
      </div>
      {(() => {
          if (redirect) {
            return <Redirect to={redirectURl} />;
          }
         
        })()}
    </AppProvider>
  );
}

export default EditField2;
