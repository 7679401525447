import React, { Fragment } from "react";
import "./index.css";
import Home from "./Home";
import Groups from "./Groups";
import Fields from "./Fields";
import Products from "./Products";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AddNewGroup from "./AddNewGroup";
import TextOnly from "./TextOnly";
import PhotoOnly from "./PhotoOnly";
import Test from "./Test";
import TextAndPhoto from "./TextAndPhoto";
import EditField from "./EditField";
import TextOnlyInstall from "./TextOnlyInstall";
import PhotoOnlyInstall from "./PhotoOnlyInstall";
import TextAndPhotoInstall from "./TextAndPhotoInstall";
import ManageGroups from "./ManageGroups";
import EditField2 from "./EditField2";
import ProductTable from "./ProductTable";
import EditField3 from "./EditField3";
import Collections from "./Collections";

export default function App() {
  return (
    <Router >
      <main>
        <Switch>
          <Route path="/" exact component={Home1} />
          <Route path="/groups" component={Group} />
          <Route path="/fields" component={Field} />
          <Route path="/products/:id/:name" component={Product} />
          <Route path="/addgroup" component={AddGroup1} />
          <Route path="/textonly" component={TextOnly1} />
          <Route path="/photoonly" component={PhotoOnly1} />
          <Route path="/textandphoto" component={TextAndPhoto1} />
          <Route path="/process" component={Test1} />
          <Route path="/editfield" component={EditField1} />
          <Route path="/textonlypaln" component={TextOnlyInstall1} />
          <Route path="/photoonlyplan" component={PhotoOnlyInstall1} />
          <Route path="/textandphotoplan" component={TextAndPhotoInstall1} />
          <Route path="/managegroups/:id/:name" component={ManageGroup1} />
          <Route path="/editfield2" component={EditFields2} />
          <Route path="/editfield3" component={EditFields3} />
          <Route path="/producttable" component={ProductTable1} />
          <Route path="/collections/:id/:name" component={Collection1} />
        </Switch>
      </main>
    </Router>
  );
}

const Home1 = () => (
  <Fragment>
    <Home />
  </Fragment>
);

const Group = ({ id }) => (
  // props.match.params.name
  <Fragment>
    <Groups />
  </Fragment>
);

const ManageGroup1 = ({
  match: {
    params: { id },
  },
}) => (
  // props.match.params.name
  <Fragment>
    <ManageGroups />
  </Fragment>
);


const Field = () => (
  <Fragment>
    <Fields />
  </Fragment>
);

const Product = () => (
  <Fragment>
    <Products />
  </Fragment>
);

const ProductTable1 = () => (
  <Fragment>
    <ProductTable />
  </Fragment>
);
const AddGroup1 = () => (
  <Fragment>
    <AddNewGroup />
  </Fragment>
);
const TextOnly1 = () => (
  <Fragment>
    <TextOnly />
  </Fragment>
);
const Test1 = () => (
  <Fragment>
    <Test />
  </Fragment>
);

const PhotoOnly1 = () => (
  <Fragment>
    <PhotoOnly />
  </Fragment>
);
const TextAndPhoto1 = () => (
  <Fragment>
    <TextAndPhoto />
  </Fragment>
);

const EditField1 = () => (
  <Fragment>
    <EditField />
  </Fragment>
);

const EditFields2 = () => (
  <Fragment>
    <EditField2 />
  </Fragment>
);

const EditFields3 = () => (
  <Fragment>
    <EditField3 />
  </Fragment>
);
const TextOnlyInstall1 = () => (
  <Fragment>
    <TextOnlyInstall />
  </Fragment>
);
const PhotoOnlyInstall1 = () => (
  <Fragment>
    <PhotoOnlyInstall />
  </Fragment>
);

const TextAndPhotoInstall1 = () => (
  <Fragment>
    <TextAndPhotoInstall />
  </Fragment>
);
const Collection1 = () => (
  <Fragment>
    <Collections />
  </Fragment>
);

