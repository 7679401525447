import React, { Component } from "react";
import "./App.css";
import { Redirect } from "react-router";
import { AppProvider, Page, Card, Layout, Button } from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import axios from "axios";

class Groups extends Component {
  //   constructor(props) {
  //     super(props);
  //    console.log(this.props.id);
  // }
  state = {
    persons: [],
    redirect: false,
  };

  componentDidMount() {
    axios
      .get(`https://apptest.webgarh.net/visualapp/api/groups.php`)
      .then((res) => {
        // const persons = res.data;
        if(res.data.status !=="404"){
        this.setState({ persons:res.data });
        }
      });
  }

  // handleClickSignIn() {
  //   console.log("come handle click fun");
  //   this.setState({ redirect: true });
  //   // history.push({
  //   //   pathname: '/about',
  //   //   search: '?the=search',
  //   //   state: { some: 'state' }
  //   // })
  // }

  render() {
    return (
      <AppProvider>
        <Page
          fullWidth
          title="Customization Groups"
          breadcrumbs={[{ content: "Home", url: "/" }]}
          // primaryAction={{ content: "Add group", url: "/addgroup" }}
        >
          {this.state.redirect ? (
            <Redirect
              to={{
                pathname: "/manageGroups",
                data: { id: 1 },
              }}
            />
          ) : null}
          <Layout>
            <Layout.Section oneHalf>
              {this.state.persons.map((person) => (
                <div style={{ marginTop: 17 }}>
                  <Card
                    title={person.group_name}
                    // secondaryFooterActions={[
                    //   { content: "Delete Group", destructive: true },
                    // ]}
                    primaryFooterAction={{
                      content: "Manage",
                      url:
                        "managegroups/" + person.id + "/" + person.group_name,
                    }}
                  >
                    <Card.Section>
                      {
                        "" + person.count ? "APPLIED ON "+ person.count +" products" : ""
                      }
                    </Card.Section>
                  </Card>
                </div>
              ))}
            </Layout.Section>
            <Layout.Section oneThird>
              <div style={{ marginTop: 17 }}>
                <Card title="What is customization Group?" sectioned>
                  <p>Manage group access by products,collections,tags</p>
                  <p>Edit group name and products from Group product list</p>
                </Card>
                <Card title="Products Vs Collections Vs Tags" sectioned>
                  <p>Manage group access by products,collections,tags</p>
                  <p>Edit products from Group product list</p>
                </Card>
              </div>
            </Layout.Section>
          </Layout>
          {/* <Card sectioned>
            <EmptyState
              heading="Add a group to get started"
              action={{ content: "Add group" , url: "/addgroup"}}
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              fullWidth
            >
              <p>You can use the Customization groups to add Groups.</p>
            </EmptyState>
          </Card> */}
          <div style={{ marginTop: 50 }}>
            <Button primary url="/process">
              Add group
            </Button>
          </div>
        </Page>
      </AppProvider>
    );
  }
}

export default Groups;
