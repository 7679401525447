import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  IndexTable,
  useIndexResourceState,
  Page,
  TextField,
  Filters,
  Select,
  Layout,
  Frame,
  Loading,
  Toast,
  ResourceItem,
  ResourceList,
  TextStyle,
} from "@shopify/polaris";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function SimpleIndexTableExample() {
  const { id, name } = useParams();
  const history = useHistory();
  const [loadingStatus, setloadingStatus] = useState(true);
  const [products, setProducts] = useState([]);
  const [temp, setTemp] = useState([]);
  const [selecedProducts, setSelecedProducts] = useState([]);
  const [loading, setLoading] = useState("block");
  const selectedProductData = () => {
    axios
      .post("https://apptest.webgarh.net/visualapp/api/selected_product.php", {
        id: id,
      })
      .then((res) => {
        if (res.data.status !== "404") {
          setSelecedProducts(res.data);
          // console.log("test",res.data);
        }
      });
        // console.log("test",selecedProducts);
       

  };
  useEffect(() => {
    if (name !== "0") {
      axios
        .post("https://apptest.webgarh.net/visualapp/api/product_api.php", {
          collection_id: name,
        })
        .then((res) => {
          setProducts(res.data);
          setTemp(res.data);
          selectedProductData();
          setLoading("none");
          setloadingStatus(false);
        });
    } else {
      axios
        .get(`https://apptest.webgarh.net/visualapp/api/product_api.php`)
        .then((res) => {
          setProducts(res.data);
          setTemp(res.data);
          selectedProductData();
          setLoading("none");
          setloadingStatus(false);
        });
    }
  }, []);

  console.log(products);

  const resourceName = {
    singular: "product",
    plural: "products",
  };

  var { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(products);
    var data = [];
  for(var i in selecedProducts) {
      data.push(selecedProducts[i].id);
  }
    // console.log(selectedTemp);
    // var temp1=[];
    if(isEmpty( selectedResources)){
    // temp1=selectedResources.concat(data.filter(value => !selectedResources.includes(value)));
    // console.log(temp1);
    selectedResources=data.slice();
    }
//     let fruits = ["apples", "bananas"];
// let vegetables = ["apples", "carrots"];
// let produce = fruits.concat(vegetables.filter(value => !fruits.includes(value)));
// console.log(produce);
  const [taggedWith, setTaggedWith] = useState(null);
  const [queryValue, setQueryValue] = useState(null);
  const [sortValue, setSortValue] = useState("today");
  console.log(selectedResources);

  const filterData = () => {
    if (queryValue !== "") {
      setProducts(
        products.filter(
          (person) =>
            person.title.includes(queryValue) ||
            person.price.includes(queryValue)
        )
      );
    } else {
      setProducts(temp);
    }
  };

  const tagData = () => {
    if (taggedWith !== "") {
      setProducts(products.filter((person) => person.tag.includes(taggedWith)));
    } else {
      setProducts(temp);
    }
  };
  // const handleChange = useCallback((newValue) => setQueryValue(newValue),saveData(), []);
  // const handleTaggedWithChange = useCallback(
  //   (value) => setTaggedWith(value),
  //   []
  // );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(
    () => setQueryValue(null),
    // saveData(),
    []
  );
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);
  const handleSortChange = useCallback((value) => setSortValue(value), []);
//  const
  const saveChange = () => {
    if (!isEmpty(selectedResources)) {
      axios
        .post("https://apptest.webgarh.net/visualapp/api/product_insert.php", {
          shop: "visual-app-final",
          product_id: JSON.stringify(selectedResources),
          handle: id,
        })
        .then((res) => {
          console.log(res.data);
          selectedProductData();
          toggleActive();
        });
    }
  };
  const filters = [
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={(newValue) => {
            setTaggedWith(newValue);
            tagData();
          }}
          labelHidden
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: "taggedWith",
          label: disambiguateLabel("taggedWith", taggedWith),
          onRemove: handleTaggedWithRemove,
        },
      ]
    : [];

  const sortOptions = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 days", value: "lastWeek" },
  ];
  const rowMarkup = products.map(({ id, title, price, image }, index) => (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedResources.includes(id)}
      position={index}
    >
      <IndexTable.Cell>
        <TextStyle variation="strong">
          <img
            src={image}
            alt=""
            style={{
              height: 70,
              width: 70,
              padding: 15,
              border: "solid 0.5px #0000004a",
              borderRadius: 3,
            }}
          />
        </TextStyle>
      </IndexTable.Cell>
      <IndexTable.Cell>{title}</IndexTable.Cell>
      <IndexTable.Cell>{price}</IndexTable.Cell>
      <IndexTable.Cell>Home Page</IndexTable.Cell>
    </IndexTable.Row>
    
  ));

  const rowMarkupSelected = selecedProducts.map(
    ({ id, title, image }, index) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={handleSelectionChange}
        position={index}
      >
       {/* {setselectedTemp(id)} */}
        <IndexTable.Cell>
          <img src={image} style={{ height: 50, width: 50 }} alt="" />
        </IndexTable.Cell>
        <IndexTable.Cell>{title}</IndexTable.Cell>
      </IndexTable.Row>
    )
  );
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content="Product list updated" onDismiss={toggleActive} />
  ) : null;

    return (
    <Page
      fullWidth
      title="Select products to apply the customizations to. "
      breadcrumbs={[{ content: "Home", onAction: () => history.goBack() }]}
      primaryAction={{ content: "save", onAction: () => saveChange() }}
    >
      <div style={{ height: "0px", display: loading }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
      <Layout>
        <Layout.Section oneHalf>
          <Card>
            <div style={{ padding: "16px", display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Filters
                  queryValue={queryValue}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  onQueryChange={(newValue) => {
                    setQueryValue(newValue);
                    filterData();
                  }}
                  onQueryClear={handleQueryValueRemove}
                  onClearAll={handleClearAll}
                  onQueryBlur={filterData}
                />
              </div>
              <div style={{ paddingLeft: "0.4rem", display: "none" }}>
                <Select
                  labelInline
                  label="Sort by"
                  options={sortOptions}
                  value={sortValue}
                  onChange={handleSortChange}
                />
              </div>
            </div>
            <IndexTable
              resourceName={resourceName}
              itemCount={products.length}
              selectedItemsCount={
                allResourcesSelected ? "All" : selectedResources.length
              }
              onSelectionChange={handleSelectionChange}
              headings={[
                { title: "" },
                { title: "Name of product" },
                { title: "Price" },
                { title: "Collection" },
              ]}
              loading={loadingStatus}
            >
              {rowMarkup}
            </IndexTable>
          </Card>
        </Layout.Section>
        <Layout.Section oneThird>
          <Card title={"Selected Products (" + rowMarkupSelected.length + ")"}>
            <ResourceList
              resourceName={{ singular: "product", plural: "products" }}
              items={selecedProducts}
              renderItem={(item) => {
                const { id, image, title } = item;

                return (
                  <ResourceItem
                    id={id}
                    url={title}
                    media={
                      <img
                        src={image}
                        style={{
                          height: 70,
                          width: 70,
                          padding: 15,
                          border: "solid 0.5px #0000004a",
                          borderRadius: 3,
                        }}
                        alt=""
                      />
                    }
                    accessibilityLabel={`View details for ${title}`}
                    name={title}
                  >
                    <h3>
                      <TextStyle variation="strong">{title}</TextStyle>
                    </h3>
                  </ResourceItem>
                );
              }}
            />
          </Card>
        </Layout.Section>
      </Layout>
      <div style={{ height: "250px" }}>
        <Frame>{toastMarkup}</Frame>
      </div>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return ` ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
}
