import React, { Component } from "react";
import "./App.css";
import {
  AppProvider,
  Page,
  Layout,
  List,
  Card,
  PageActions,
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";

class TextOnly extends Component {
  render() {
    return (
      <AppProvider>
        <Page
          title="Photo Only Plan"
          breadcrumbs={[{ content: "Home", url: "/photoonly" }]}
        >
          <div style={{ margin: 10 }}></div>
          <Layout>
            <Layout.Section oneThird>
              <Card
                title="Paid"
                // secondaryFooterActions={[{ content: "Edit shipment" }]}
                // primaryFooterAction={{ content: "Add tracking number" }}
              >
                <Card.Section>
                  <List>
                    <List.Item>Price: $4.99</List.Item>
                    <List.Item>Use product image as background</List.Item>
                    <List.Item>Rotate uploaded image</List.Item>
                    <List.Item>Uploaded image Size control</List.Item>
                    <List.Item>Move over product image</List.Item>
                    <List.Item>Set Image dimentions</List.Item>
                  </List>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
          <PageActions
            primaryAction={{
              content: "Add Plan",
              url: "/",
            }}
          />
        </Page>
      </AppProvider>
    );
  }
}

export default TextOnly;
