import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
// import Home from "./Home";
// import Test from "./Test";
// import Groups from "./Groups";
// import Products from "./Products";
// import AddNewGroup from "./AddNewGroup";
// import Fields from "./Fields";
import Routes from "./Routes";

function App() {
  return (
    <BrowserRouter>
      <AppProvider i18n={translations}>
        {/* <Home/> */}
        {/* <Home />
      <Groups />
      <AddNewGroup />
      <Products />
      <Fields /> */}
        {/* <Test /> */}
        <Routes />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
