import React, { useState, useCallback, useEffect } from "react";
import "./App.css";
import axios from "axios";
import {
  AppProvider,
  Page,
  Button,
  Layout,
  List,
  Card,
  ChoiceList,
  Form,
  FormLayout,
  TextField,
  Select,
  Toast,
  Frame,
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import { Redirect } from "react-router";


function EditField() {
  const [selected, setSelected] = useState(["1"]);

  const [redirect, setredirect]=useState(false);

  const [redirectURl, setredirectURl]=useState(false);

  const handleChange2 = useCallback((value) => setSelected(value), []);

  const [selected3, setSelected3] = useState(["1"]);

  const handleChange3 = useCallback((value) => setSelected3(value), []);

  const [selected4, setSelected4] = useState(["1"]);

  const handleChange4 = useCallback((value) => setSelected4(value), []);

  const [maxTextWords, setMaxTextWords] = useState("500");

  const handleMaxTextWords = useCallback(
    (newValue) => setMaxTextWords(newValue),
    []
  );

  const [charallowed, setCharallowed] = useState("Alphanumeric");

  const handleCharallowed = useCallback((value) => setCharallowed(value), []);

  const options = [
    { label: "Only alphabets", value: "alphabets" },
    { label: "Only digits", value: "digits" },
    { label: "Alphanumeric", value: "Alphanumeric" },
  ];

  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content="Field Updated" onDismiss={toggleActive} />
  ) : null;

  useEffect(() => {
    // const url= window.location.hostname;
    // const data1=url.split(".");
    // // console.log(data1[0]);
    // const shop = {
    //   // shop: data1[0],

    // };
    axios
      .post("https://apptest.webgarh.net/visualapp/text_setting_update.php", {
        shop: "visual-app-final",
      })
      .then((res) => {
        console.log(res.data);
        setSelected3(res.data.font_size);
        setSelected(res.data.font_color);
        setSelected4(res.data.fonts);
        if (res.data.max_text !== "") {
          setMaxTextWords(res.data.max_text);
        }
        if (res.data.characters_allowed !== "") {
          setCharallowed(res.data.characters_allowed);
        }
      });
  }, []);

  const handleSave = () => {
    // const minDimen=minDimentionHeight+"/"+minDimentionWidth;
    // const maxDimen=maxDimentionHeight+"/"+maxDimentionWidth;
    axios
      .post("https://apptest.webgarh.net/visualapp/text_setting_update.php", {
        shop: "visual-app-final",
        fontSizeValue: selected3[0],
        fontsValue: selected4[0],
        textStylesValue: "0",
        fontColorValue: selected[0],
        moveTextValue: "0",
        max_text: maxTextWords,
        characters_allowed: charallowed,
      })
      .then((res) => {
        console.log(res.data);
        setSelected3(res.data.font_size);
        setSelected(res.data.font_color);
        setSelected4(res.data.fonts);
        if (res.data.max_text !== "") {
          setMaxTextWords(res.data.max_text);
        }
        if (res.data.characters_allowed !== "") {
          setCharallowed(res.data.characters_allowed);
        }
      });

    toggleActive();
  };

  return (
    <AppProvider>
      <Page
      fullWidth
        title="Edit Field"
        breadcrumbs={[{ content: "Home", url:"/process" }]}
        primaryAction={{ content: "save", onAction: () => handleSave() }}
      >
        <Layout>
          <Layout.Section oneHalf>
            <Card title="Edit Field (001)" sectioned>
              {/* <List>
                <List.Item>Price: Paid ($5.00)</List.Item>
                <List.Item>Customizable image over the product photo</List.Item>
              </List> */}
              <Form>
                <FormLayout>
                  <ChoiceList
                    title="Allow visual customization of text?"
                    choices={[
                      { label: "Yes", value: "1" },
                      { label: "No", value: "0" },
                    ]}
                    selected={selected3}
                    onChange={handleChange3}
                  />
                  <div style={{ marginTop: 20 }}></div>
                  <ChoiceList
                    title="Use product image as background?"
                    choices={[
                      { label: "Yes", value: "1" },
                      { label: "No", value: "0" },
                    ]}
                    selected={selected}
                    onChange={handleChange2}
                  />
                  <div style={{ marginTop: 20, maxWidth: 300 }}>
                    <TextField
                      label="Max text words:"
                      type="number"
                      value={maxTextWords}
                      onChange={handleMaxTextWords}
                    />
                  </div>
                  <div style={{ marginTop: 20, maxWidth: 300 }}>
                    <Select
                      label="Characters allowed:"
                      options={options}
                      onChange={handleCharallowed}
                      value={charallowed}
                    />
                  </div>

                  <div style={{ marginTop: 20 }}></div>
                  <ChoiceList
                    title="Allow spaces ?"
                    choices={[
                      { label: "Yes", value: "1" },
                      { label: "No", value: "0" },
                    ]}
                    selected={selected4}
                    onChange={handleChange4}
                  />
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>
          <div style={{marginBottom:20}} onClick={()=>{setredirectURl('/editfield2');setredirect(true);}}>
            <Card title="Field 002" sectioned>
              <List>
                <List.Item>Price type: Paid ($4.99)</List.Item>
                <List.Item>Ordinary text entry</List.Item>
              </List>
              <p> </p>
            </Card>
            </div>
            <div style={{marginBottom:20}} onClick={()=>{setredirectURl('/editfield3');setredirect(true);}}>
            <Card title="Field 003" sectioned>
              <List>
                <List.Item>Price: Paid ($6.99)</List.Item>
                <List.Item>Customizable image over the product photo</List.Item>
              </List>
              <p> </p>
            </Card>
            </div>
            <div className="App" style={{ marginTop: 50 }}>
              <Button primary url={process.env.PUBLIC_URL} >
                Add New
              </Button>
            </div>
          </Layout.Section>
        </Layout>
      </Page>
      <div style={{ height: "250px" }}>
        <Frame>{toastMarkup}</Frame>
      </div>
      {(() => {
          if (redirect) {
            return <Redirect to={redirectURl} />;
          }
         
        })()}
    </AppProvider>
  );
}

export default EditField;
