import React, { useState, useEffect } from "react";
import {
  TextStyle,
  Card,
  IndexTable,
  useIndexResourceState,
  Page,
} from "@shopify/polaris";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router";

export default function Collections() {
  const { id } = useParams();
  const [collection, setCollection] = useState([]);
  useEffect(() => {
    axios
      .get(`https://apptest.webgarh.net/visualapp/api/collections.php`)
      .then((res) => {
        setCollection(res.data.custom_collections);
      });
  }, []);

  const resourceName = {
    singular: "collection",
    plural: "collections",
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(collection);
    
  const rowMarkup = collection.map(({ id, title }, index) => (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedResources.includes(id)}
      position={index}
    >
      <IndexTable.Cell>
        <TextStyle variation="strong">
          <img
            src="https://apptest.webgarh.net/visualapp/no_img1.png"
            alt=""
            style={{ height: 70, width: 70, padding: 15 }}
          />
        </TextStyle>
      </IndexTable.Cell>
      <IndexTable.Cell>{title}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page fullWidth title="Collections" breadcrumbs={[{ content: "", url: "/groups" }]}>
      <Card>
        <IndexTable
          resourceName={resourceName}
          itemCount={collection.length}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          headings={[{ title: "" }, { title: "Title" }]}
        >
          {rowMarkup}
          {(() => {
              const redirectUrl = "/products/" + id + "/" + selectedResources[0];
          if (selectedResources.length > 0 ) {
            return <Redirect to={redirectUrl} />;
          }
        })()}
        </IndexTable>
      </Card>
    </Page>
  );
}
