import React, { useCallback, useState } from "react";
import "./App.css";
import {
  Page,
  Card,
  Layout,
  Button,
  Stack,
  Collapsible,
  TextContainer,
  PageActions,
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";

function Fields() {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);

  return (
    <Page title="Manage fields">
      <div style={{ marginTop: 7 }}></div>
      <Layout>
        <Layout.Section oneThird>
          <div style={{ height: "200px" }} onClick={handleToggle1}>
            <Card title="Field 001" sectioned>
              <div>
                <Stack vertical>
                  <Collapsible
                    open={open1}
                    id="basic-collapsible"
                    transition={{
                      duration: "500ms",
                      timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                  >
                    <TextContainer>
                      <p>Price type: free Ordinary text entry</p>
                    </TextContainer>
                    <div style={{ textAlign: "right", marginTop: 10 }}>
                      <Button
                        onClick={handleToggle1}
                        ariaExpanded={open1}
                        ariaControls="basic-collapsible"
                        url="/editfield"
                                                primary
                      >
                        Edit
                      </Button>
                    </div>
                  </Collapsible>
                </Stack>
              </div>
            </Card>
          </div>
        </Layout.Section>
        <Layout.Section oneThird>
          <div style={{ height: "200px" }} onClick={handleToggle2}>
            <Card title="Field 002" sectioned>
              <Stack vertical>
                <Collapsible
                  open={open2}
                  id="basic-collapsible"
                  transition={{
                    duration: "500ms",
                    timingFunction: "ease-in-out",
                  }}
                  expandOnPrint
                >
                  <TextContainer>
                    <p></p>
                  </TextContainer>
                  <div style={{ textAlign: "right", marginTop: 10 }}>
                    <Button
                      onClick={handleToggle1}
                      ariaExpanded={open1}
                      ariaControls="basic-collapsible"
                      url="/editfield2"

                      primary
                    >
                      Edit
                    </Button>
                  </div>
                </Collapsible>
              </Stack>
            </Card>
          </div>
        </Layout.Section>
        <Layout.Section oneThird>
          <div style={{ height: "200px" }} onClick={handleToggle3}>
            <Card title="Field 003" sectioned>
              <Stack vertical>
                <Collapsible
                  open={open3}
                  id="basic-collapsible"
                  transition={{
                    duration: "500ms",
                    timingFunction: "ease-in-out",
                  }}
                  expandOnPrint
                >
                  <TextContainer>
                    <p>
                      Price: Paid ($5.00) Customizable image over the product
                      photo
                    </p>
                  </TextContainer>
                  <div style={{ textAlign: "right", marginTop: 10 }}>
                    <Button
                      onClick={handleToggle1}
                      ariaExpanded={open1}
                      ariaControls="basic-collapsible"
                      url="/editfield3"
                      primary
                    >
                      Edit
                    </Button>
                  </div>
                </Collapsible>
              </Stack>
            </Card>
          </div>
        </Layout.Section>
        <Layout.Section oneThird>
          <Button primary>Add new</Button>
        </Layout.Section>
      </Layout>
      <PageActions
        primaryAction={{
          content: "Next",
        }}
      />
    </Page>
  );
}

export default Fields;
